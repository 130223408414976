const state = {
  currentCategorie: null,
  currentIngredient: null,
};

const mutations = {
  updateIngredient(nextState, ingredient){
    nextState.currentMeal = ingredient;
  },
  updateCategorie(nextState, categorie){
    nextState.currentCategorie = categorie;
  },
};

const actions =  {
  setCurrentMeal: (context, ingredient) => context.commit('updateIngredient', ingredient),
  removeCurrentMeal: (context) => context.commit('updateIngredient', null),
  setCurrentCategorie: (context, categorie) => context.commit('updateCategorie', categorie),
  removeCurrentCategorie: (context) => context.commit('updateCategorie', null),
};

export default {
  state,
  actions,
  mutations,
};
