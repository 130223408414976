import Vue from 'vue';
import Vuex from 'vuex';
import menu from '@/store/menu';
import order from '@/store/order';
import brand from '@/store/brand';
import member from '@/store/member';
import ingredient from '@/store/ingredient';
import marketing from '@/store/marketing';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    langcode: 'zh',
    isLogin: false,
    backendUrl: process.env.VUE_APP_BACKEND_URL,
    apiUrl: process.env.VUE_APP_API_URL,
  },
  mutations: {
    setLang(nextState, langcode){
      nextState.langcode = langcode;
    },
    setLoginStatus(nextState, loginStatus){
      nextState.isLogin = loginStatus;
    },
  },
  actions: {
    async changeLang(context, langcode){
      context.commit('setLang', langcode);
    },
    async startLogin(context){
      context.commit('setLoginStatus', true);
    },
    async startLogout(context){
      context.commit('setLoginStatus', false);
    },
  },
  modules: {
    menu,
    order,
    member,
    brand,
    ingredient,
    marketing,
  }
})
