const state = {
    currentTime: null,
  };
  
  const mutations = {
    updateTime(nextState, time){
      nextState.currentTime = time;
    },
  };
  
  const actions =  {
    setCurrentTime: (context, time) => context.commit('updateTime', time),
    removeCurrentTime: (context) => context.commit('updateTime', null),
  };
  
  export default {
    state,
    actions,
    mutations,
  };
  