import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Brands
  {
    path: '/brands',
    name: 'Brands',
    component: () => import('../views/brand/Brands.vue')
  },
  {
    path: '/brands/add',
    name: 'AddBrand',
    component: () => import('../views/brand/AddBrand.vue')
  },
  {
    path: '/brands/edit/:id',
    name: 'EditBrand',
    component: () => import('../views/brand/EditBrand.vue')
  },
  //Restaurants
  {
    path: '/restaurants',
    name: 'Restaurants',
    component: () => import('../views/brand/Restaurants.vue')
  },
  {
    path: '/restaurant/add',
    name: 'AddRestaurant',
    component: () => import('../views/brand/AddRestaurant.vue')
  },
  {
    path: '/restaurant/edit/:id',
    name: 'EditRestaurant',
    component: () => import('../views/brand/EditRestaurant.vue')
  },

  //Seats
  {
    path: '/seats',
    name: 'Seats',
    component: () => import('../views/seat/Seats.vue')
  },
  {
    path: '/seats/list',
    name: 'SeatList',
    component: () => import('../views/seat/SeatList.vue')
  },


  //Meals
  {
    path: '/meals',
    name: 'Meals',
    component: () => import('../views/menu/Meals.vue')
  },
  {
    path: '/meal/add',
    name: 'AddMeal',
    component: () => import('../views/menu/AddMeal.vue')
  },
  {
    path: '/meal/edit/:id',
    name: 'EditMeal',
    component: () => import('../views/menu/EditMeal.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/menu/Categories.vue')
  },
  {
    path: '/categorie/add',
    name: 'AddCategorie',
    component: () => import('../views/menu/AddCategorie.vue')
  },
  {
    path: '/categorie/edit/:id',
    name: 'EditCategorie',
    component: () => import('../views/menu/EditCategorie.vue')
  },
  {
    path: '/set-meals',
    name: 'SetMeals',
    component: () => import('../views/menu/SetMeals.vue')
  },
  {
    path: '/set-meal/add',
    name: 'AddSetMeal',
    component: () => import('../views/menu/AddSetMeal.vue')
  },

  //庫存
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/inventory/Inventory.vue')
  },
  {
    path: '/inventory/add',
    name: 'AddIngredient',
    component: () => import('../views/inventory/AddIngredient.vue')
  },
  {
    path: '/vendor',
    name: 'Vendor',
    component: () => import('../views/inventory/Vendor.vue')
  },
  {
    path: '/ordering-records',
    name: 'OrderingRecord',
    component: () => import('../views/inventory/OrderingRecords.vue')
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: () => import('../views/inventory/Transfer.vue')
  },

  //Delivery
  {
    path: '/delivery/staffs',
    name: 'DeliveryStaffs',
    component: () => import('../views/delivery/Staffs.vue')
  },
  {
    path: '/delivery/customer',
    name: 'DeliveryCutomers',
    component: () => import('../views/delivery/Customers.vue')
  },

  //Report
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report/DateReport.vue')
  },

  //Members
  {
    path: '/members',
    name: 'Members',
    component: () => import('../views/member/Members.vue')
  },

  //Marketing
  {
    path: '/time-period',
    name: 'TimePeriod',
    component: () => import('../views/marketing/TimePeriod.vue')
  },
  {
    path: '/time-period/add',
    name: 'CreateTimePeriod',
    component: () => import('../views/marketing/CreateTimePeriod.vue')
  },
  {
    path: '/time-period/:id',
    name: 'ViewTimePeriod',
    component: () => import('../views/marketing/ViewTimePeriod.vue')
  },
  {
    path: '/special-day',
    name: 'SpecialDay',
    component: () => import('../views/marketing/SpecialDay.vue')
  },
  //Settings
  {
    path: '/settings/account',
    name: 'AccountSettings',
    component: () => import('../views/setting/AccountSettings.vue')
  },
  {
    path: '/settings/system',
    name: 'SystemSettings',
    component: () => import('../views/setting/SystemSettings.vue')
  },
  {
    path: '/settings/staff-record',
    name: 'StaffRecord',
    component: () => import('../views/setting/StaffRecord.vue')
  },
  {
    path: '/settings/backup',
    name: 'Backup',
    component: () => import('../views/setting/Backup.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
