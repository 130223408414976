import queryString from 'query-string';

const Restaurant = {
  async loadAllRestaurants(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/restaurant?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading restaurants information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadRestaurant(baseApiUrl, restaurantId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/restaurant/${restaurantId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading restaurant information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createRestaurant(baseApiUrl, restaurantInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        token: loginInfo['token'],
        restaurantInfo: restaurantInfo,
      };

      const apiUrl = `${baseApiUrl}/restaurant`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create restaurant information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async updateRestaurant(baseApiUrl, restaurantId, restaurantInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        restaurantInfo: restaurantInfo,
      };

      const apiUrl = `${baseApiUrl}/restaurant/${restaurantId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update restaurant information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteRestaurant(baseApiUrl, restaurantId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/restaurant/${restaurantId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete restaurant information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
}

export default Restaurant;
