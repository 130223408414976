<template>
  <div class="topbar">
    <header class="main-header" id="header">
      <nav class="navbar navbar-expand-md navbar-light">
        <div class="navbar-language-switcher">
          <el-dropdown trigger="click" @command="selectLang">
            <span class="el-dropdown-link">
              <span v-if="langcode === 'en'">
                English<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <span v-if="langcode === 'zh'">
                繁體中文<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="zh">繁體中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <el-dropdown trigger="click" @command="selectLang">
                <span class="el-dropdown-link">
                  <span v-if="brands.length === 0">
                    暫無品牌
                  </span>
                  <span v-else>
                    目前品牌：{{ targetBrand.name }}
                  </span>
                </span>
                <el-dropdown-menu v-if="brands.length !== 0" slot="dropdown">
                  <el-dropdown-item :key="brand.id" v-for="brand in brands" :command="brand.id">{{ brand.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li class="nav-item">
              <el-dropdown trigger="click" @command="selectLang">
                <span class="el-dropdown-link">
                  <span v-if="restaurants.length === 0">
                    暫無餐廳
                  </span>
                  <span v-else>
                    目前餐廳：{{ targetRestaurant.name }}
                  </span>
                </span>
                <el-dropdown-menu v-if="restaurants.length !== 0" slot="dropdown">
                  <el-dropdown-item :key="restaurant.id" v-for="restaurant in restaurants" :command="restaurant.id">{{ restaurant.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>

            <li class="nav-item">
              <el-dropdown trigger="click" @command="handleAction">
                <span class="el-dropdown-link">
                  User<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>{{ $t('Account Setting') }}</el-dropdown-item>
                  <el-dropdown-item command="logout">{{ $t('Logout') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Cookies from 'js-cookie';
import Restaurant from '@/lib/restaurant';
import Brand from '@/lib/brand';

export default {
  name: 'Topbar',
  data(){
    return {
      restaurants: [],
      brands: [],
    }
  },
  mounted(){
    this.loadRestaurants();
    this.loadBrands();
  },
  methods:{
    async loadBrands(){
      try{
        const loginInfo = Common.getLoginInfo();
        const brands = await Brand.loadAllBrands(this.apiUrl, loginInfo);
        this.brands = brands;
        this.setTargetBrand(brands[0]);
      }catch(err){
        console.log(err);
      }
    },
    async loadRestaurants(){
      try{
        const loginInfo = Common.getLoginInfo();
        let restaurants = await Restaurant.loadAllRestaurants(this.apiUrl, loginInfo);
        this.setTargetRestaurant(restaurants[0]);
        this.restaurants = restaurants;
      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Admin Panel Title');
    },
    handleAction(command){
      switch(command){
        case 'logout':
         this.logout();
        break;
      }
    },
    logout(){
      Cookies.remove('loginToken');
      Cookies.remove('userId');
      this.startLogout();
      this.$router.push('/');
    },
    ...mapActions(['startLogout', 'changeLang', 'setTargetBrand', 'setTargetRestaurant']),
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
   targetBrand: state => state.brand.targetBrand,
   targetRestaurant: state => state.brand.targetRestaurant,
 }),
}
</script>

<style scoped lang="scss">
.topbar{
  position: sticky;
  top: 0;
  z-index: 50;
  width: 100%;
  background-color: white;
  padding-left: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.nav-item{
  margin-right: 20px;
  &:last-child{
    margin-left: 20px;
  }
}
.logo{
  width: 40px;
}
.navbar-placeholder{
  flex: 1;
  color: black;
}
.el-dropdown-link{
  color: black;
  &:hover{
    cursor: pointer;
  }
}
#collapsibleNavbar{
  justify-content: flex-end;
  .navbar-nav{
    display: flex;
    align-items: center;
    a{
      color: black;
    }
  }
}
</style>
