const state = {
  currentMember: null,
};

const mutations = {
  updateMember(nextState, member){
    nextState.currentMember = member;
  },
};

const actions =  {
  setCurrentMember: (context, member) => context.commit('updateMember', member),
  removeCurrentMember: (context) => context.commit('updateMember', null),
};

export default {
  state,
  actions,
  mutations,
};
