const state = {
  currentBrand: null,
  currentRestaurant: null,
  targetBrand: null,
  targetRestaurant: null,
};

const mutations = {
  switchBrand(nextState, brand){
    nextState.targetBrand = brand;
  },
  switchRestaurant(nextState, restaurant){
    nextState.targetRestaurant = restaurant;
  },
  updateBrand(nextState, brand){
    nextState.currentBrand = brand;
  },
  updateRestaurant(nextState, restaurant){
    nextState.currentRestaurant = restaurant;
  },
};

const actions =  {
  setTargetBrand: (context, brand) => context.commit('switchBrand', brand),
  setTargetRestaurant: (context, restaurant) => context.commit('switchRestaurant', restaurant),

  setCurrentBrand: (context, brand) => context.commit('updateBrand', brand),
  removeCurrentBrand: (context) => context.commit('updateBrand', null),
  setCurrentRestaurant: (context, restaurant) => context.commit('updateRestaurant', restaurant),
  removeCurrentRestaurant: (context) => context.commit('updateRestaurant', null),
};

export default {
  state,
  actions,
  mutations,
};
