const state = {
  currentCategorie: null,
  currentMeal: null,
};

const mutations = {
  updateMeal(nextState, product){
    nextState.currentMeal = product;
  },
  updateCategorie(nextState, categorie){
    nextState.currentCategorie = categorie;
  },
};

const actions =  {
  setCurrentMeal: (context, product) => context.commit('updateMeal', product),
  removeCurrentMeal: (context) => context.commit('updateMeal', null),
  setCurrentCategorie: (context, categorie) => context.commit('updateCategorie', categorie),
  removeCurrentCategorie: (context) => context.commit('updateCategorie', null),
};

export default {
  state,
  actions,
  mutations,
};
